<template>
    <div class="login-content">
        <card>
            <h1 slot="header" class="title">Login</h1>
            <div class="px-md-1">
                <base-input 
                    label="Username"
                    placeholder="Username"
                    v-model="username"
                >
                </base-input>
            </div>
            <div class="px-md-1">
                <base-input 
                    label="Color"
                    placeholder="Color"
                    v-model="color"
                    type="color"
                >
                </base-input>
            </div>
            <base-button slot="footer" type="primary" fill @click="login">Login</base-button>
        </card>
    </div>
</template>
<script>
  export default {
    components: {
    },
    data() {
      return {
      }
    },
    computed: {
        username: {
            get () {
                return this.$store.state.user.username;
            },
            set (value) {
                this.$store.commit('updateUsername', value);
            }
        },
        color: {
            get () {
                return this.$store.state.user.color;
            },
            set (value) {
                this.$store.commit('updateColor', value);
            }
        }
    },
    methods: {
        login() {
            console.log(this.$store.getters.user)
            if(this.$store.getters.isLoggedIn) this.$router.push({ path: '/' });
        }
    }
  }
</script>
<style lang="scss" scoped>
.content {
    width: 100vw;
    height: 100vh;
}

.login-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;

    input[type="color"] {
        padding: 0;
    }
}
</style>